body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

h1::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  margin: auto;
  margin-top: 4px;
}

.langsbtn {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #141e30,
    #243b55
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #141e30, #243b55);
  padding: 6%;
  transition: all 1s ease;
  border-radius: 40px;
}
.langsbtn:hover {
  transform: scale(1.2);
  border-radius: 10px;
}
.langs {
  color: white;
}
