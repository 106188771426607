html {
  width: 100%;
  margin: 0;
  padding: 0;
}
.bm {
  margin-bottom: 5%;
}

.grow {
  width: 80px;
}
@media only screen and (max-width: 858px) {
  .grow {
    width: 50px;
  }
}
@media only screen and (max-width: 564px) {
  .grow {
    width: 30px;
  }
}
@media only screen and (max-width: 402px) {
  .grow {
    width: 20px;
  }
  .fnt {
    font-size: "10px";
  }
}
